import React from 'react'
// import logo from './logo.svg'

import './App.css'

function Home () {
  return (
    <div className="Home">
      <p>
        Foobara is a software framework with a focus on applications with complex domains!
      </p>
    </div>
  )
}

export default Home
